import Logo from "../assets/LogoSvg.svg";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  // Typography
} from "@mui/material";
import { Stack } from "@mui/system";
import { useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  // useNavigate
} from "react-router-dom";
import { Typography12 } from "./Typography/Typography";
// import { useState } from "react";

const Header = (props: any) => {
  console.log(props.collapsed);
  const login = useSelector((state: any) => state.user.login);
  const navigate = useNavigate();
  // const [logoutStatus, setlogoutStatus] = useState(false);
  // const handleOnClickLogout = () => {
  //   localStorage.removeItem("Token");
  //   setlogoutStatus(true);
  // };
  // if (logoutStatus) {
  //   navigate("/auth/login");
  // }
  const handleclick = () => {
    navigate("/");
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{ backgroundColor: "white" }}
        elevation={1}
      >
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            {props.collapsed === undefined ? (
              <Stack>
                <Box>
                  <img src={Logo} alt="" />
                </Box>
                <Typography12
                  sx={{ ml: 3.5, mt: -1.5 }}
                  text={"AFFLIATE"}
                ></Typography12>
              </Stack>
            ) : (
              ""
            )}
          </div>

          <div>
            {/* {!login ? (
              <Link to={"/"}>
                <Button
                  size="small"
                  color="inherit"
                  sx={{ px: 4 }}
                  style={{
                    maxWidth: "320px",
                    textTransform: "none",
                    fontSize: 15,
                    borderRadius: "8px",
                    backgroundColor: "rgba(145, 158, 171, 0.24)",
                    fontFamily: "Public Sans",
                    fontWeight: "700",
                    color: "black",
                  }}
                  fullWidth
                >
                  Login{" "}
                </Button>
              </Link>
            ) : ( */}
            {login ? (
              <Link to={"/"}>
                <Button
                  size="small"
                  color="inherit"
                  onClick={handleclick}
                  sx={{ px: 4 }}
                  style={{
                    maxWidth: "320px",
                    textTransform: "none",
                    fontSize: 15,
                    borderRadius: "8px",
                    backgroundColor: "rgba(145, 158, 171, 0.24)",
                    fontFamily: "Public Sans",
                    fontWeight: "700",
                    color: "black",
                  }}
                  // onClick={handleOnClickLogout}
                  fullWidth
                >
                  Log Out{" "}
                </Button>
              </Link>
            ) : (
              ""
            )}

            {/* )} */}
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
