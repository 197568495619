// import "./styles.css";
import React, { useState } from "react";
import { Avatar, Stack, Box } from "@mui/material";
import { Link } from "react-router-dom";
import LogoSign from "../assets/Assure_Logo.svg";
import { firstCharacterUppercase } from "../utils/characterCases/Cases";

import APLogo from "../assets/LogoSvg.svg";
import DashboardIcon from "../assets/ic_dashboard.svg";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import {
  Typography12,
  Typography14,
} from "../components/Typography/Typography";
import { useSelector } from "react-redux";
const drawerWidth = 270;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
function Sidebars() {
  const userName = useSelector((state: any) => state.user.userData.username);
  const [userManagementMenu, setuserManagementMenu] = useState(false);
  const [referralMenu, setreferralMenu] = useState(false);
  const [paymentMenu, setpaymentMenu] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [OnBoardingMenu, setOnBoardingMenu] = useState(false);

  const handleDrawerClose = () => {
    setuserManagementMenu(false);
    setreferralMenu(false);
    setpaymentMenu(false);
    setOnBoardingMenu(false);
    setOpen(!open);
  };

  return (
    <>
      <Drawer variant="permanent" open={open} anchor="left">
        <Box sx={{ backgroundColor: "white" }}>
          <DrawerHeader>
            <IconButton
              onClick={handleDrawerClose}
              // style={{ position: "fixed", overflow: "inherit" }}
            >
              {!open ? (
                <img src={LogoSign} alt="" />
              ) : (
                <img src={APLogo} alt="" />
              )}
            </IconButton>
          </DrawerHeader>
        </Box>

        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Box px={1}>
              <Box
                sx={{
                  borderRadius: "12px",
                  px: 0.5,
                  py: 3,
                  maxWidth: "240px",
                  backgroundColor: "rgba(145, 158, 171, 0.08)",
                }}
              >
                <Stack direction={"row"} display={"flex"} spacing={2}>
                  <Avatar />
                  <ListItemText
                    primary={
                      <Stack>
                        <Typography14
                          text={
                            userName ? firstCharacterUppercase(userName) : "N/A"
                          }
                          color={"rgba(33, 43, 54, 1)"}
                        ></Typography14>
                        <Typography12
                          sx={{ mt: -0.5 }}
                          text={"User"}
                        ></Typography12>
                      </Stack>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </Stack>
              </Box>
            </Box>
          </ListItem>
        </List>
        {/* <Divider /> */}
        <List>
          {open ? (
            <Stack px={2} pt={1}>
              <Typography12 fontWeight={700} text={"GENERAL"}></Typography12>
            </Stack>
          ) : (
            ""
          )}

          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {<img src={DashboardIcon} alt="" />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography14
                    text={"Dashboard"}
                    fontSize={20}
                    // color={"rgba(48, 57, 129, 1)"}
                  ></Typography14>
                }
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <Link to={"/app/invite"} style={{ textDecoration: "none" }}>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* {<img src={DashboardIcon} alt="" />} */}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography14
                      text={"Invite a Business"}
                      fontSize={20}
                      // color={"rgba(48, 57, 129, 1)"}
                    ></Typography14>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>

          <Link to={"/app/businessusers"} style={{ textDecoration: "none" }}>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* {<img src={DashboardIcon} alt="" />} */}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography14
                      text={"View Invitations"}
                      fontSize={20}
                      // color={"rgba(48, 57, 129, 1)"}
                    ></Typography14>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>

          <Link to={"/app/commission"} style={{ textDecoration: "none" }}>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* {<img src={DashboardIcon} alt="" />} */}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography14
                      text={"My Commissions"}
                      fontSize={20}
                    ></Typography14>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>

          <Link to={"/app/accounts"} style={{ textDecoration: "none" }}>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* {<img src={DashboardIcon} alt="" />} */}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography14
                      text={"Settlement Bank Accounts"}
                      fontSize={20}
                    ></Typography14>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>

          {/* ))} */}
        </List>
      </Drawer>
    </>
  );
}
export default Sidebars;
