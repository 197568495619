import AffliateLoginSvg from "../../assets/illustration_maintenance.svg";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Container,
  Typography,
  Box,
  TextField,
  Stack,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser, logOutUser, onVerify } from "../../redux/slices/admin";
import { toast } from "react-hot-toast";
import { emailValidation } from "../../utils/Validations/ValidationsFn";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import SimpleLoader from "../../components/Loader";
import Header from "../../components/Header";
import { CharacterLowercase } from "../../utils/characterCases/Cases";

export default function LoginPage() {
  const dispatch = useDispatch();
  const [username, setusername] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [password, setPassword] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  // // Email Validation
  // const validateEmail = () => {
  //   const E_V = emailValidation(username);
  //   if (E_V === false) {
  //     setEmailErrorMsg(true);
  //   } else {
  //     setEmailErrorMsg(false);
  //   }
  // };

  // Set Email State
  const handleonChangeEmail = (e: any) => {
    setEmailErrorMsg(false);
    setusername(e.target.value);
  };

  // Set Password State
  const handleonChangePassword = (e: any) => {
    setPasswordErrorMsg(false);
    setPassword(e.target.value);
  };

  // handleFormSubmit
  const handleFormSubmitLogin = async (e: any) => {
    setBtnDisabled(true);
    setOpen(true);
    e.preventDefault();
    let data = new FormData(e.currentTarget);
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let body = {
      username: CharacterLowercase(data.get("username")),
      password: data.get("password"),
      request: "affiliatePartner03",
    };
    const FetchData = await axios
      .post("https://api.staging.assuredpay.in/auth/login", body, axiosConfig)
      .then((res) => {
        if (res.status === 200 && res.data.Active === true) {
          // action that save values in store during login
          dispatch(loginUser(res.data.result));
          // dispatch(loginUser(res.data.results));
          dispatch(onVerify(res.data.result));

          setOpen(false);
          toast("Welcome to Two-step Verification !", {
            style: {
              color: "green",
              fontFamily: "Public Sans",
              fontWeight: 500,
            },
          });
          navigate("verification");
        }
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
        setPasswordErrorMsg(true);
        setBtnDisabled(false);
      });
  };
  useEffect(() => {
    if ((username && password) !== "") {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  });
  useEffect(() => {
    dispatch(logOutUser({}));
  });
  return (
    <>
      <Box margin={2} sx={{ flexGrow: 1 }}>
        <Container>
          <Box
            margin={2}
            justifyContent={"center"}
            sx={{ display: "flex", flexDirection: "column" }}
            alignItems={"center"}
          >
            <Grid container spacing={{ xs: 5, md: 8, lg: 12 }}>
              <Grid>
                <Box
                  sx={{
                    boxShadow: 1,
                    borderRadius: 2,
                    px: 6,
                    pt: 4,
                    pb: 1,
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: "360px",
                  }}
                >
                  <Stack spacing={0.5} sx={{ mb: 3, position: "relative" }}>
                    <Typography
                      variant="h5"
                      component="div"
                      fontSize={22}
                      fontWeight={700}
                      fontFamily={"Public Sans"}
                      lineHeight={"30px"}
                      color={"#334A67"}
                    >
                      Sign into your Dashboard
                    </Typography>

                    <Box
                      component="form"
                      onSubmit={handleFormSubmitLogin}
                      noValidate
                      sx={{ mt: 1 }}
                    >
                      <TextField
                        margin="normal"
                        error={emailErrorMsg}
                        helperText={
                          emailErrorMsg ? "Email Invalid Address" : ""
                        }
                        sx={{
                          "& .MuiFormLabel-root": {
                            color: "#919EAB",
                            fontFamily: "Public Sans",
                            fontWeight: "500",
                            fontSize: 14,
                          },
                          "& .MuiFormHelperText-root": {
                            fontFamily: "Public Sans",
                            fontWeight: 500,
                          },
                        }}
                        fullWidth
                        name="username"
                        label="Username"
                        id="username"
                        onChange={handleonChangeEmail}
                        type="text"
                        size="small"
                        InputProps={{
                          style: {
                            borderRadius: "8px",
                            height: "40px",
                            borderColor: "#919EAB",
                          },
                        }}
                      />
                      <TextField
                        margin="normal"
                        sx={{
                          "& .MuiFormLabel-root": {
                            color: "#919EAB",
                            fontFamily: "Public Sans",
                            fontWeight: "500",
                            fontSize: 14,
                          },
                          "& .MuiFormHelperText-root": {
                            fontFamily: "Public Sans",
                            fontWeight: 500,
                          },
                        }}
                        fullWidth
                        name="password"
                        label="Enter Your Password"
                        id="password"
                        error={passwordErrorMsg}
                        autoComplete="current-password"
                        helperText={
                          passwordErrorMsg
                            ? "Wrong Password, Please try again !"
                            : ""
                        }
                        type={showPassword ? "text" : "password"}
                        size="small"
                        onChange={handleonChangePassword}
                        InputProps={{
                          style: {
                            borderRadius: "8px",
                            height: "40px",
                            borderColor: "#919EAB",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              {" "}
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Button
                        disabled={btnDisabled}
                        size="small"
                        color="inherit"
                        sx={{
                          my: 3,
                          mb: "8px",
                          py: 0.7,
                          borderRadius: 2,
                          textTransform: "none",
                        }}
                        style={{
                          fontSize: 14,
                          backgroundColor: btnDisabled
                            ? "rgba(145, 158, 171, 0.24)"
                            : "#303981",
                          fontFamily: "Public Sans",
                          fontWeight: "700",
                          color: btnDisabled
                            ? "var(--action-light-disabled, rgba(145, 158, 171, 0.80))"
                            : "white",
                        }}
                        fullWidth
                        variant="contained"
                        type="submit"
                      >
                        Login{" "}
                      </Button>
                      <SimpleLoader props={open} />
                      <div style={{ display: "flex", justifyContent: "start" }}>
                        <Link
                          to="/forgotpassword"
                          style={{ textDecoration: "none" }}
                        >
                          <Typography
                            fontWeight={400}
                            marginTop={1}
                            fontSize={14}
                            fontFamily={"Public Sans"}
                            color={"#334A67"}
                          >
                            {" "}
                            Forgot Password?
                          </Typography>
                        </Link>
                      </div>
                    </Box>
                  </Stack>
                </Box>
                {/* </Container> */}
              </Grid>
              <Grid>
                <img
                  src={AffliateLoginSvg}
                  style={{
                    marginTop: "60px",
                    maxHeight: "350px",
                    maxWidth: "400px",
                    alignItems: "center",
                  }}
                  alt=""
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
