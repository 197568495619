import { useRoutes } from "react-router-dom";
import {
  BankSettlement,
  InviteMain,
  InviteUsers,
  MyCommission,
} from "./elements";

export function Router() {
  return useRoutes([
    {
      path: "/invite",
      element: <InviteMain />,
    },
    {
      path: "/businessusers",
      element: <InviteUsers />,
    },
    {
      path: "/commission",
      element: <MyCommission />,
    },
    {
      path: "/accounts",
      element: <BankSettlement />,
    },
  ]);
}
