// // ----------------------------------------------------------------------
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  error: null,
  userLogin: <any>[],
  BusinessKycDetails: <any>[],
  login: false,
  userData: <any>{},
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.login = true;
    },
    loginUser: (state, action) => {
      state.isLoading = true;
      state.userLogin = action.payload;
    },
    logOutUser: (state, action) => {
      state.isLoading = true;
      state.login = false;
      state.userLogin = {};
    },
    onVerify: (state, action) => {
      state.isLoading = true;
      state.userData = action.payload;
    },
  },
});
export const { loginUser, logOutUser, login, onVerify } = userSlice.actions;

export default userSlice.reducer;
