import { Suspense, lazy, ElementType } from "react";
// components
import LoadingScreen from "../components/loadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------
export const InviteMain = Loadable(
  lazy(() => import("../pages/Invitaion/Invite/InviteMain"))
);
export const InviteUsers = Loadable(
  lazy(() => import("../pages/Invitaion/viewInvites/InviteUsers"))
);
export const MyCommission = Loadable(
  lazy(() => import("../pages/Invitaion/MyCommission/Main"))
);
export const BankSettlement = Loadable(
  lazy(() => import("../pages/Invitaion/Accounts/BankList"))
);
