import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import { Router } from "./routes";
import { Toaster } from "react-hot-toast";
import LoginPage from "./pages/auth/LoginPage";
import NotAuthorised from "./components/NotAuthorised";
import TwoStepVerification from "./pages/auth/TwoStepAuthentication";
import { useProSidebar } from "react-pro-sidebar";
import ForgotPassword from "./pages/auth/ForgotPassword";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Sidebars from "./components/Sidebars";

export default function App() {
  const collapsed = useProSidebar().collapsed;
  const token = useSelector((state: any) => state.user.userLogin.token);
  // const token = "";
  const ForgotPassowrdRouter = () => {
    return (
      <div>
        <Toaster />
        <Header />
        <ForgotPassword />
      </div>
    );
  };

  // This Router Facility is provide sidebars for application.
  const DashboardRouter = () => {
    console.log("toekekkeekkeekkeke", token);
    if (token !== undefined && token !== "") {
      return (
        <Box sx={{ display: "flex" }}>
          <Sidebars />
          <Box
            component="main"
            sx={{
              // margin: 4,
              flexGrow: 1,
              height: "100vh",
              // backgroundColor: "#F7F9FB",
              // backgroundColor: "",
            }}
          >
            {/* <DrawerHeader /> */}
            <Toaster />
            <Header collapsed={collapsed} />
            <Router />{" "}
          </Box>
        </Box>
      );
    } else {
      return (
        <div>
          <Toaster />
          <Header />
          <NotAuthorised />
        </div>
      );
    }
  };
  const LoginRouter = () => {
    return (
      <div>
        <Header />
        <LoginPage />
      </div>
    );
  };
  const TwoStepVerificationRouter = () => {
    return (
      <div>
        <Toaster />
        <Header />
        <TwoStepVerification />
      </div>
    );
  };

  return (
    <Routes>
      <Route path="/" element={LoginRouter()} />
      <Route path="/verification" element={TwoStepVerificationRouter()} />
      <Route path="/forgotpassword" element={ForgotPassowrdRouter()} />
      <Route path="/app/*" element={DashboardRouter()}></Route>
    </Routes>
  );
}
