import AdminLoginSvg from "../../assets/illustration_maintenance.svg";
import Grid from "@mui/material/Unstable_Grid2";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DoneIcon from "@mui/icons-material/Done";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import {
  Container,
  Typography,
  Box,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function ForgotPassword() {
  const navigate = useNavigate();

  // user Verify with err msg & validations
  const [username, setusername] = useState("");
  const [userNamedisabled, setUserNamedisabled] = useState(false);
  const [userNameErrorMsg, setuserNameErrorMsg] = useState(false);
  const [VerifybtnDisabled, setVerifyBtnDisabled] = useState(true);
  const [showOtpVerifyField, setshowOtpVerifyField] = useState(false);
  const [VerifyLoading, setVerifyLoading] = useState(false);

  // user OTP Verify with err msg & validations with success msg
  const [LoginbtnDisabled, setLoginBtnDisabled] = useState(true);
  const [OTP, setOTP] = useState("");
  const [InvalidOTPErMsg, setInvalidOTPErMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);

  // Set UserName State & verify enable with remove error msg (logic)
  // successfull verification for two way factore otp generate forgot password
  const handleonChangeUserName = (e: any) => {
    setuserNameErrorMsg(false);
    if (e.target.value !== "") {
      setusername(e.target.value);
      setVerifyBtnDisabled(false);
      setusername(e.target.value);
    } else {
      setVerifyBtnDisabled(true);
    }
  };
  const handleFormSubmitVerify = async (e: any) => {
    setVerifyLoading(true);
    setUserNamedisabled(true);
    setVerifyBtnDisabled(true);
    e.preventDefault();
    let data = new FormData(e.currentTarget);
    const FetchData = await axios
      .post("https://api.staging.assuredpay.in/auth/sendforgotpassotp", {
        username: data.get("username"),
      })
      .then((res) => {
        if (res.status === 200 && res.data.Active === true) {
          setVerifyLoading(false);
          setUserNamedisabled(true);
          setshowOtpVerifyField(true);
        }
      })
      .catch((err) => {
        setVerifyLoading(false);
        setUserNamedisabled(false);
        setuserNameErrorMsg(true);
      });
  };

  // set Opt and provide enable forward process
  // form submit with validations and success msg
  const handleonChangeEnterOTP = (e: any) => {
    setInvalidOTPErMsg(false);
    if (e.target.value !== "") {
      setOTP(e.target.value);
      setLoginBtnDisabled(false);
    } else {
      setLoginBtnDisabled(true);
    }
  };
  const handleFormSubmitContinue = async (e: any) => {
    setLoginBtnDisabled(true);
    e.preventDefault();
    let data = new FormData(e.currentTarget);
    const FetchData = await axios
      .post("https://api.staging.assuredpay.in/auth/forgot-password", {
        username: username,
        otp: data.get("OTP"),
      })
      .then((res) => {
        if (res.status === 200 && res.data.Active === true) {
          setSuccessMsg(true);
          // action that save values in store during login
          // dispatch(loginUser(res.data.result.updatedUser));
          toast("Password Successfully Sent to your Email Address!", {
            style: {
              color: "green",
              fontFamily: "Public Sans",
              fontWeight: 500,
            },
          });
          navigate("/");
        }
      })
      .catch((err) => {
        setInvalidOTPErMsg(true);
        setLoginBtnDisabled(true);
      });
  };
  return (
    <>
      <Box margin={2} sx={{ flexGrow: 1 }}>
        <Container>
          <Box
            margin={4}
            justifyContent={"center"}
            sx={{ display: "flex", flexDirection: "column" }}
            alignItems={"center"}
          >
            <Grid container spacing={{ xs: 5, md: 9, lg: 13 }}>
              <Grid>
                <Box
                  sx={{
                    boxShadow: 1,
                    borderRadius: 2,
                    px: 6,
                    pt: 4,
                    pb: 1,
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: "380px",
                  }}
                >
                  <Stack spacing={0.5} sx={{ mb: 3, position: "relative" }}>
                    <Typography
                      variant="h6"
                      component="div"
                      fontSize={22}
                      fontWeight={700}
                      fontFamily={"Public Sans"}
                      lineHeight={"30px"}
                      color={"#334A67"}
                    >
                      Forgot Password
                    </Typography>
                    <Typography
                      component="div"
                      fontSize={12}
                      fontWeight={500}
                      fontFamily={"Public Sans"}
                      color={"#556486"}
                    >
                      Enter the email address associated with your account and
                      <br />
                      we'll send your password to your registered email address.
                    </Typography>
                    <Box
                      component="form"
                      onSubmit={handleFormSubmitVerify}
                      noValidate
                      sx={{ mt: 1 }}
                    >
                      <Stack
                        display={"flex"}
                        direction={"row"}
                        spacing={3}
                        sx={{ mt: 3 }}
                      >
                        <TextField
                          margin="normal"
                          disabled={userNamedisabled}
                          error={userNameErrorMsg}
                          helperText={
                            userNameErrorMsg
                              ? "Invalid Username, Please try again !"
                              : ""
                          }
                          sx={{
                            "& .MuiFormLabel-root": {
                              color: "#919EAB",
                              fontFamily: "Public Sans",
                              fontWeight: "500",
                              fontSize: 14,
                            },
                            "& .MuiFormHelperText-root": {
                              fontFamily: "Public Sans",
                              fontWeight: 500,
                            },
                          }}
                          fullWidth
                          name="username"
                          label="Enter Your Username"
                          id="username"
                          onChange={handleonChangeUserName}
                          type="email"
                          size="small"
                          InputProps={{
                            style: {
                              borderRadius: "8px",
                              height: "40px",
                              borderColor: "#919EAB",
                            },
                          }}
                        />
                        <LoadingButton
                          size="medium"
                          disabled={VerifybtnDisabled}
                          sx={{ p: 1 }}
                          style={{
                            minWidth: "100px",
                            marginLeft: 12,
                            textTransform: "none",
                            backgroundColor: VerifybtnDisabled
                              ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                              : "#00AB55",
                            color: VerifybtnDisabled ? "" : "white",
                            borderRadius: "8px",
                            fontSize: "14px",
                            fontWeight: "700",
                            height: "5vh",
                            textAlign: "center",
                          }}
                          loading={VerifyLoading}
                          type="submit"
                        >
                          Get OTP
                        </LoadingButton>
                      </Stack>
                    </Box>
                    {showOtpVerifyField ? (
                      <Box
                        component="form"
                        onSubmit={handleFormSubmitContinue}
                        noValidate
                        sx={{ mt: 1 }}
                      >
                        <Stack
                          display={"flex"}
                          direction={"row"}
                          spacing={3}
                          sx={{ mt: 3 }}
                        >
                          <TextField
                            margin="normal"
                            error={InvalidOTPErMsg}
                            helperText={
                              InvalidOTPErMsg
                                ? "Invalid OTP, Please try again !"
                                : ""
                            }
                            sx={{
                              "& .MuiFormLabel-root": {
                                color: "#919EAB",
                                fontFamily: "Public Sans",
                                fontWeight: "500",
                                fontSize: 14,
                              },
                              "& .MuiFormHelperText-root": {
                                fontFamily: "Public Sans",
                                fontWeight: 500,
                              },
                            }}
                            fullWidth
                            name="OTP"
                            label="Enter Verify OTP"
                            id="OTP"
                            onChange={handleonChangeEnterOTP}
                            type="text"
                            size="small"
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                                height: "40px",
                                borderColor: "#919EAB",
                              },
                            }}
                          />
                        </Stack>
                        {successMsg ? (
                          <Stack display={"flex"} direction="row" spacing={0.5}>
                            <>
                              <DoneIcon
                                style={{ fontSize: "15px", color: "#36B37E" }}
                              />{" "}
                              <Typography
                                fontWeight={600}
                                fontSize={11}
                                fontFamily={"Public Sans"}
                                color={"#36B37E"}
                              >
                                {" "}
                                Password Sent Successfully
                              </Typography>
                            </>
                          </Stack>
                        ) : (
                          ""
                        )}
                        <Button
                          disabled={LoginbtnDisabled}
                          size="small"
                          color="inherit"
                          sx={{
                            my: 2.5,
                            mb: "8px",
                            py: 0.7,
                            borderRadius: 2,
                            textTransform: "none",
                          }}
                          style={{
                            fontSize: 14,
                            backgroundColor: LoginbtnDisabled
                              ? "rgba(145, 158, 171, 0.24)"
                              : "#303981",
                            fontFamily: "Public Sans",
                            fontWeight: "700",
                            color: LoginbtnDisabled
                              ? "var(--action-light-disabled, rgba(145, 158, 171, 0.80))"
                              : "white",
                          }}
                          fullWidth
                          variant="contained"
                          type="submit"
                        >
                          Continue{" "}
                        </Button>
                      </Box>
                    ) : (
                      ""
                    )}
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <Stack
                          display={"flex"}
                          direction={"row"}
                          sx={{ mt: 2 }}
                        >
                          <ArrowBackIosIcon
                            sx={{ fontSize: "15px", mt: 1.2 }}
                          />
                          <Typography
                            fontWeight={600}
                            marginTop={1}
                            fontSize={13}
                            fontFamily={"Public Sans"}
                            color={"#556486"}
                          >
                            Back to Login
                          </Typography>
                        </Stack>
                      </Link>
                    </div>
                  </Stack>
                </Box>
              </Grid>
              <Grid>
                <img
                  src={AdminLoginSvg}
                  style={{
                    marginTop: "60px",
                    maxHeight: "350px",
                    maxWidth: "400px",
                    alignItems: "center",
                  }}
                  alt=""
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
